.counter {
  display: flex;
  justify-content: space-between;
  font-size: 1.5rem;
  color: #333;

  margin-left: 5%;
  margin-right: 5%;

}

.elementCounter {
  width: calc(50% - 20px); /* Dwa elementy w jednym wierszu */
  margin-bottom: 20px;
  text-align: center;
  
  padding-left: 0;
  padding-right: 0;
  background-color: #ffd700;
  border-radius: 8px 8px 0px 0px;
  box-shadow: rgba(0, 0, 0, 0.1);
  font-size: 18px;
  font-weight: bold;

  margin: 1%;
}

.count{
  font-weight: bold;
  color: #001f3f;
  
}

#centeredElement {
  margin-left: auto; /* Wyśrodkuj ostatni element */
  margin-left: 1%;
}

@media screen and (max-width: 1111px) {

  .counter {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;

  }

  .elementCounter {
    padding: 0%;
    width: calc(50% - 20px); /* Dwa elementy w jednym wierszu na mniejszych ekranach */
    font-size: 12px;

  }

  #centeredElement {
    width: 100%; /* Na mniejszych ekranach jeden element na całą szerokość */
  }
}