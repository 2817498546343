/* Navbar.module.css */

.nav {
  background-color: #001f3f;
  color: #f8f8f8;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 100;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
}

.navContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
}

.logo {
  color: #f8f8f8;
  text-decoration: none;
  font-size: 24px;
  font-weight: bold;
}

.menuIcon {
  display: none;
  flex-direction: column;
  cursor: pointer;
}

.menuIcon.open {
  transform: rotate(90deg);
}

.bar {
  width: 30px;
  height: 4px;
  background-color: #f8f8f8;
  margin: 6px 0;
  transition: 0.4s;
}

.navLinks {
  list-style: none;
  display: flex;
  justify-content: space-around;
  padding: 0;
  margin: 0;
}

.subMenu {
  display: none;
  position: absolute;
  background-color: rgba(0, 31, 63, 0.8);
  box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.1);
  padding: 1rem;
  top: 100%;
  left: 0;
  width: 200%;
  z-index: 1;
}

.navServiceLink:hover .subMenu,
.navServiceLink.open .subMenu {
  display: flex;
  flex-direction: column;
}

.navLinks li {
  margin: 0 1rem;
  position: relative;
}

.navLinks a {
  text-decoration: none;
  color: #f8f8f8;
  font-weight: bold;
  transition: color 0.3s;
  padding: 0.5rem 1rem;
  display: inline-block;
}

.navLinks a:hover {
  color: #ff9900;
}

@media screen and (max-width: 768px) {
  .menuIcon {
    display: flex;
  }
  .navLinks {
    display: none;
    flex-direction: column;
    width: 100%;
    position: absolute;
    top: 100%;
    left: 0;
    background-color: #001f3f;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    border-top: solid;
    border-color: #ffd700;
    border-width: 1px;
    z-index: 1;
  }
  .navLinks.open {
    display: flex;
  }
  .subMenu {
    display: flex;
    position: static;
    background-color: #001f3f;
    box-shadow: none;
  }
  .navServiceLink:hover .subMenu,
  .navServiceLink.open .subMenu {
    display: flex;
    position: static;
    background-color: #001f3f;
    box-shadow: none;
  }
}

@media screen and (min-width: 769px) {
  .menuIcon {
    display: none;
  }
  .navLinks {
    display: flex !important;
    position: static !important;
  }
  .subMenu {
    display: none;
  }
}
