.serviceContainer {
  display: flex;
  align-items: flex-start; /* Zmieniono align-items na flex-start */
  justify-content: space-between; /* Dodano justify-content */
  margin-bottom: 40px;
  padding: 20px;
  background-color: #f8f8f8;
  border-radius: 10px;
  border: 2px solid #001f3f;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);

}

.serviceContent {
  flex: 1;
  padding-right: 20px; /* Dodano odstęp z prawej strony */
}

.serviceTitle {
  font-size: 1.8rem;
  margin-bottom: 10px;
  color: #ffd700;
  background-color: #001f3f;
  padding: 10px;
  border-radius: 5px;
}

.serviceDescription {
  list-style-type: square;
  color: #001f3f;
  margin-top: 10px;
}

.serviceImage {
  flex: 1;
  display: flex; /* Dodano właściwość display: flex; */
  align-items: center; /* Dodano właściwość align-items: center; */
  justify-content: center; /* Dodano właściwość justify-content: center; */
}

.serviceImage img {
  max-width: 100%;
  max-height: 300px;
  object-fit: cover;
  border-radius: 8px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

@media screen and (max-width: 1200px) {
  .serviceContainer {
    flex-direction: column;
    align-items: center;
  }

  .serviceImage {
    order: 2;
    margin-top: 20px;
  }
}
