
.container{

    background-image: url(https://imageselektryk.s3.eu-central-1.amazonaws.com/elektryk.jpg);
    background-size:cover;


}

.allWidgets {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    max-width: 950px; /* Dostosuj szerokość kontenera */
    background-color: transparent;
    
  }


.ServiceWidget {
    width: calc(33.33% - 2%); /* Aby uzyskać 3 kolumny na szerokim ekranie */
    margin-top: 1%; /* Odstęp między elementami */
    margin-bottom: 1%; /* Odstęp między elementami */
    background-color: #001f3f;
    text-align: center;
    padding: 1%;
    box-sizing: border-box;
    transition: background-color 0.4s;
    color: #ffffff;
  }

.ServiceWidget:hover{
    background-color: #ffd700;
    color: black;

}

@media screen and (max-width: 768px) {
    .container {
      flex-direction: column; /* Zmień układ na kolumnowy */
    }
    .container {
      width: 100%; /* Elementy zajmują całą dostępną szerokość */
      height: auto; /* Wysokość dostosowana do zawartości */
      margin-bottom: 16px; /* Dodaj odstęp między elementami */
      background-image: none;
      margin-left: 0%;
      margin-right: 0%;
      
    }

    .ServiceWidget {
        width: calc(50% - 2%);
    }
  }