/* Services.module.css */
.title {
  background-color: #001f3f;
  color: #ffd700;
  font-size: 30px;
  padding: 1rem;
  margin: 0;

}

.serviceItems {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 5%;
  background-color: #f8f8f8;
}

.serviceItem {
  text-align: left;
  padding: 10px;
  border: solid;
  border-width: 1px;
  transition: transform 0.3s ease-in-out, background-color 0.3s ease-in-out;
  border-radius: 10px 10px 0 0;
  background-color: #001f3f;
  color: white;
  border-radius: 10px;

}

.serviceName{
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-bottom: 2px solid #ffd700;
}

.serviceItem img {
  max-width: 100%;
  border-radius: 8px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 5);
}

/* Przykład responsywności: */
@media screen and (max-width: 768px) {
  .serviceItems {
    flex-direction: column;
    margin: 0;

  }

  .serviceItems{
    padding: 0;
  }
  .serviceItem {
    width: 100%;
    height: auto;
    margin-bottom: 16px;
    padding-left: 10px; /* Add units to the padding value */
    padding-right: 10px; /* Reduce or remove right padding if necessary */
    box-sizing: border-box; /* Include padding and border in element's total width and height */
    border-radius: 0px 0px 10px 10px;
    border-width: 0;
  }


}

.serviceItem:hover {
  background-color: #ffd700;
  color: #001f3f;
}

@media  screen and (min-width: 769px) {
  .serviceItem:hover {
    transform: scale(1.1);
  }
}
