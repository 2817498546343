/* Stopka */
.footers {
    padding: 1rem 0;
    background-color: #333;
    color: #fff;
}

.footers p {
    text-align: center;
}

@media screen and (max-width: 768px) {
    .footers {
      flex-direction: column; /* Zmień układ na kolumnowy */
    }
    .footers {
      width: 100%; /* Elementy zajmują całą dostępną szerokość */
      height: auto; /* Wysokość dostosowana do zawartości */
      margin-bottom: 16px; /* Dodaj odstęp między elementami */
      padding: 0%;
    }
  }