/* Contact.module.css */
.contact {
  background-color: #f8f8f8;
  padding: 3rem;
  border-radius: 10px 10px 0px 0px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  margin-top: 2rem;
}

.contactHeader {
  color: #001f3f;
  font-size: 2rem;
  text-align: center;
  border-bottom: 2px solid #ffd700;
  padding-bottom: 1rem;
  margin-bottom: 1.5rem;
}

.contactText {
  text-align: center;
  color: #333;
  margin-bottom: 1.5rem;
  font-size: 1.2rem;
}

.contactInfo {
  text-align: center;
  color: #001f3f;
  font-weight: bold;
  margin: 0;
}

.contactInfo span {
  color: #001f3f;
  margin-right: 0.5rem;
  font-size: 1.2rem;
  font-size: bold;

}