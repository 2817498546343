.contactinfo {
    display: flex;
    background-color: #001f3f;
    padding-left: 5%;
    padding-right: 5%;
    justify-content: space-between;
    align-items: center;

    font-size: 30px;
    color: #ffd700;
    

  }
.call{
    font-weight: bold;
}

.phone{
    font-size: 40px;
    font-weight: bold;
    padding: 20px;
    color: black;
    white-space:nowrap;
    background-color: #ffd700;
    transition: 0.4s;
}

.phone:hover{
    color: black;
    background-color: white;
    
}

@media screen and (max-width: 768px) {
    .contactinfo {
      flex-direction: column; /* Zmień układ na kolumnowy */
    }
    .contactinfo {


      margin-bottom: 16px; /* Dodaj odstęp między elementami */
      font-size: 20px;
      
    }
    .phone{
        font-size: 30px;
    }
  }