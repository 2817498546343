/* Carousel.module.css */

.carousel {
  display: flex;
  overflow: hidden;
  width: 100%;
  margin: 0 auto;
  position: relative;
}

.slidesContainer {
  display: flex;
  transition: transform 1s ease-in-out;
}

.slide {
  flex: 0 0 100%;
  text-align: center;
  display: flex; /* Add this */
  flex-direction: column; /* Add this */
  align-items: center; /* Add this */
  justify-content: center; /* Add this */
}

.slide img {
  width: 100%;
  max-height: 400px;
  object-fit: cover;
}

.slide p {
  background-color: #001f3f;
  color: #ffd700;
  padding: 1rem;
  font-size: 1.2em;
  font-weight: bold;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  text-align: center; /* Add this */
  width: 100%; /* Add this */
  box-sizing: border-box; /* Add this */
}

/* Add this media query */
@media (max-width: 600px) {
  .slide img {
    width: 100%; /* Reduce the width to 80% on small screens */
    max-height: 30vh;
  }

  .slide p {
    font-size: 15px;

    padding: 1rem 0;
  }
}