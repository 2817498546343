
.container {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    background-color: transparent;
    margin-left: 15%;
    margin-right: 15%;
  }
 
.container p, h2 {
    text-align: center;
}
  .Rate {
    background-color: #ffd700;
    margin: 10px;
    padding: 20px;
    border-radius: 10px;
  }
  
  .Rate h2 {
    font-size: 18px;
    margin: 0;
  }
  
  .Rate p {
    font-size: 14px;
    margin: 0;
    margin-top: 10px;
    font-style: italic;
  }

  @media screen and (max-width: 768px) {
    .container {
      flex-direction: column; /* Zmień układ na kolumnowy */
    }
    .container {
      width: 100%; /* Elementy zajmują całą dostępną szerokość */
      height: auto; /* Wysokość dostosowana do zawartości */
      margin-bottom: 16px; /* Dodaj odstęp między elementami */
      padding: 0%;
      margin-left: 0%;
      margin-right: 0%;
    }
  }